<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h3>
        Competência:<strong> {{ competencyInfo.name }}</strong>
      </h3>
      <Divider />
      <Button
        label="Voltar à listagem"
        class="p-button-success p-button-outlined"
        @click="$router.push(`/competency-profile`)"
      />
      <Divider />
      <div
        class="p-field p-col-12 p-md-3"
        v-for="competency in competencyInfo.users"
        :key="competency.id"
      >
        <h3>{{ competency.user }}</h3>
        <Rating v-model="competency.value" :cancel="false" :disabled="true" />
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import competencyService from "../services/competency.service";

export default {
  name: "CompetencyProfileView",

  data() {
    return {
      loading: true,
      competencyInfo: [],
    };
  },
  async created() {
    this.competencyInfo = await this.getUserCompetencyInfo();
    this.loading = false;
  },
  methods: {
    getUserCompetencyInfo() {
      return competencyService.getUserCompetencyInfo(this.$route.params.id);
    },
  },
};
</script>
